<template>
  <v-sheet class="employee" id="employee" style="height: calc(100vh - 140px)">
    <v-row>
      <v-layout class="">

        <v-col md="4" class="" v-for="(status, index) in status_list" :key="`span-loop-${index}`">
          <v-card @click="statusSelect(status.dbvalue)" class="mx-auto"
            :style="`border: 0.5px solid ${getBgColor(status.value)}`">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title class="custom-form-label me-0" style="font-weight: 600; font-size: 18px">
                    <VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="me-3">
                      <v-icon size="34" :style="`color : ${getAvatarColor(status.value)}`" dark>person</v-icon>
                    </VAvatar>
                    <!-- <v-icon
                      large
                      class="custom-icon-label me-2"
                      style="background-color: #90caf9; color: white"
                      >person</v-icon
                    > -->
                    {{ status.description }}
                  </v-list-item-title>
                </v-list-item-content>
                <div align="center" justify="end">
                  <span class="subheading" v-if="status.value == 'all'">
                    <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600">{{ all }}</v-chip>
                  </span>
                  <span class="subheading" v-else-if="status.value == 'active'">
                    <v-chip color="#4CAF4E" style="font-size: 18px; color: white; font-weight: 600">{{
                      status.employee_status_count }}</v-chip>
                  </span>
                  <span class="subheading" v-else>
                    <v-chip color="#E53935" style="font-size: 18px; color: white; font-weight: 600">{{
                      status.employee_status_count }}</v-chip>
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- <v-col md="3" class="">
          <v-card class="mx-auto" style="border: 0.5px solid #81c784">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <v-icon
                      large
                      class="custom-icon-label me-2"
                      style="background-color: #81c784; color: white"
                      >mdi-calendar</v-icon
                    >
                    Last 4 Month Ave..
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip color="#1B5E20" style="font-size: 18px; color: white; font-weight: 600"
                      >45</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col> -->
      </v-layout>
    </v-row>

    <v-card-text class="d-none">
      <v-row>
        <v-layout>
          <v-col md="4">
            <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
              <v-card-actions>
                <v-list-item class="grow px-0">
                  <v-list-item-content>
                    <v-list-item-title class="custom-form-label me-0" style="font-weight: 600; font-size: 18px">
                      <v-icon large class="custom-icon-label me-2"
                        style="background-color: #90caf9; color: white">person</v-icon>TOTAL EMPLOYEE</v-list-item-title>
                  </v-list-item-content>
                  <div align="center" justify="end">
                    <span class="subheading">
                      <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600">145</v-chip>
                    </span>
                  </div>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col md="4">
            <v-card class="mx-auto" style="border: 0.5px solid #4caf4e">
              <v-card-actions>
                <v-list-item class="grow px-0">
                  <v-list-item-content>
                    <v-list-item-title class="custom-form-label" style="font-weight: 600; font-size: 16px">
                      <v-icon large class="custom-icon-label me-2"
                        style="background-color: #4caf4e; color: white">mdi-calendar</v-icon>ACTIVE
                      EMPLOYEE</v-list-item-title>
                  </v-list-item-content>

                  <div align="center" justify="end">
                    <span class="subheading">
                      <v-chip color="#4CAF4E" style="font-size: 18px; color: white; font-weight: 600">5</v-chip>
                    </span>
                  </div>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col md="4">
            <v-card class="mx-auto" style="border: 0.5px solid #e57373">
              <v-card-actions>
                <v-list-item class="grow px-0">
                  <v-list-item-content>
                    <v-list-item-title class="custom-form-label" style="font-weight: 600; font-size: 16px">
                      <v-icon large class="custom-icon-label me-2"
                        style="background-color: #e57373; color: white">mdi-calendar</v-icon>INACTIVE
                      EMPLOYEE</v-list-item-title>
                  </v-list-item-content>

                  <div align="center" justify="end">
                    <span class="subheading">
                      <v-chip color="#E53935" style="font-size: 18px; color: white; font-weight: 600">20</v-chip></span>
                  </div>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
          <!-- <v-col md="3" class="">
            <v-card class="mx-auto" style="border: 0.5px solid #81c784">
              <v-card-actions>
                <v-list-item class="grow px-0">
                  <v-list-item-content>
                    <v-list-item-title
                      class="custom-form-label"
                      style="font-weight: 600; font-size: 16px"
                    >
                      <v-icon
                        large
                        class="custom-icon-label me-2"
                        style="background-color: #81c784; color: white"
                        >mdi-calendar</v-icon
                      >
                      Last 4 Month Ave..
                    </v-list-item-title>
                  </v-list-item-content>
  
                  <div align="center" justify="end">
                    <span class="subheading"
                      ><v-chip color="#1B5E20" style="font-size: 18px; color: white; font-weight: 600"
                        >45</v-chip
                      ></span
                    >
                  </div>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col> -->
        </v-layout>
      </v-row>
    </v-card-text>

    <v-card-text class="py-0 px-0">
      <v-row>
        <v-col class="mt-4">
          <EmployeeHeader moduleType="purchaseorder" :dataLoading="dataLoading" :stat="selectstatus"
            :status-list="status_list" allkey="all_purchase" countkey="status_count"
            v-on:saveAddress="updateAddressPerson"></EmployeeHeader>
        </v-col>
        <v-col class="py-0 px-0 mt-3" style="max-width: 180px; width: 180px">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600">Filter By Employee</label>
          <AutoCompleteInput hide-details :disabled="pageLoading" :loading="pageLoading" :items="employee" id="segment"
            item-text="first_name" v-model="search.employees" item-value="id" placeholder="Select Employee">
          </AutoCompleteInput>
        </v-col>
        <v-col class="py-0 pr-0 mt-3" style="max-width: 180px; width: 180px">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600">Filter by Month</label>

          <AutoCompleteInput hide-details :disabled="pageLoading" :loading="pageLoading" :items="months" item-text="name"
            item-value="value" v-model="search.month" placeholder="Select Month">
          </AutoCompleteInput>
        </v-col>
        
        <!-- <v-col class="py-0 pr-0" style="max-width: 130px; width: 130px">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter By Department</label
          >
          <SelectInput
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="['Kitchen', 'Floor', 'Office']"
            id="segment"
            item-text="`name`"
            v-model="search.department"
            item-value="id"
            placeholder="Select Department"
          ></SelectInput>
        </v-col> -->
        <v-col md="4" class="mt-5 pr-0">
            <v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="blue darken-4" v-on="on" v-bind="attrs" v-on:click="searchEmployee" depressed tile
                    class="ml-2 white--text me-1">Filter</v-btn>
                </template>
                <span>Filter</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red darken-4" v-on="on" v-bind="attrs" depressed v-on:click="resetEmployee" tile
                    class="ml-2 white--text me-2">Reset Filter</v-btn>
                </template>
                <span>Reset Filter</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    class="ml-2 white--text"
                    v-on:click="exportEmployee"
                    >Export</v-btn
                  >
                </template>
                <span>Export</span>
              </v-tooltip> -->
              <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn color="blue darken-4" depressed tile v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                      class="white--text">
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item draggable @dragstart="dragstart(index)" @dragover="dragover(index)" @dragend="dragEnd"
                  v-for="(item, index) in defaultColDefs" :key="item.field + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move">
                  <div class="d-flex flex-row">
                    <template v-if="!item.checkbox || Number(item.checkbox) == 0">
                      <v-checkbox :ripple="false" hide-details color="#0d47a1" v-model="defaultColShow"
                        v-bind:value="item.field" :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()" class="m-0"></v-checkbox>

                      <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                      <v-icon>drag_indicator</v-icon>
                    </template>
                  </div>
                  <v-divider class="mt-1 mb-0" style="width: 100%"></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn :disabled="pageLoading" color="blue darken-4" depressed tile class="ml-2 white--text"
              :to="{ name: 'create-employee', query: { t: new Date().getTime() } }">
              Create Employee
            </v-btn>
            </v-flex>
        </v-col>

      </v-row>
    </v-card-text>

    <SearchCriteria v-if="listingSearch" :search="listingSearch" search-string="Item #, Name, Category"
      v-on:close-search="resetSearch"></SearchCriteria>

    <v-row>
      <v-col md="12">
        <v-simple-table fixed-header class="bt-table table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="heading in customThead" :key="heading.title" style="background-color: #f5f5f5">
                  <div>{{ heading.headerName }}</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <!-- v-if="employeeList.length" -->
              <!-- {{ employeeList }} -->
              <template v-if="employeeList.length">
                <tr v-for="(row, bkey) in employeeList" :key="bkey" :class="bkey % 2 === 0 && 'blue lighten-5'"
                  class="employee-listing-tr" link v-on:click="routeToDetail(row)">
                  <td v-for="(th, index) in customThead" :key="'key_' + index">
                    <div v-if="th.field === 'id'"></div>

                    <div v-else-if="th.field === 'action'">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-btn v-on:click.stop.prevent="deleteConfirm(row.first_name, row.id)" depressed fab dark
                              x-small @click="goBrodcastupdate(row.id)" color="blue darken-4 white--text">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="th.field === 'status'" link class="cursor-pointer">
                      <v-switch v-model="row.activated" inset v-on:click.prevent.stop="activeInactive(row)"
                        color="success"></v-switch>
                    </div>
                    <div v-else-if="th.field === 'barcode'" link v-on:click="routeToDetail(row)" class="cursor-pointer">
                      <v-chip class="ma-2" color="teal" text-color="white" label>
                        <ValueTemplate :title="'barcode'" :value="row.barcode"></ValueTemplate>
                      </v-chip>
                    </div>

                    <div v-else-if="th.field === 'joining_date'" link v-on:click="routeToDetail(row)"
                      class="cursor-pointer">
                      <ValueTemplate :title="'joining date'" :value="formatedateTime(row.joining_date)"></ValueTemplate>
                    </div>

                    <div v-else-if="th.field === 'first_name'" link v-on:click="routeToDetail(row)"
                      class="cursor-pointer">
                      <ValueTemplate :title="'no name'" :value="row.first_name"></ValueTemplate>
                    </div>

                    <div v-else-if="th.field === 'phone_number'" link v-on:click="routeToDetail(row)"
                      class="cursor-pointer">
                      <ValueTemplate :title="'no phone number'" :value="row.phone_number"></ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'probation_period'" link v-on:click="routeToDetail(row)"
                      class="cursor-pointer">
                      <v-chip color="#0D47A1" outlined>
                        <ValueTemplate :title="'no probation period'" :value="row.probation_period"></ValueTemplate>
                      </v-chip>
                    </div>
                    <div v-else-if="th.field === 'job_type'" link v-on:click="routeToDetail(row)" class="cursor-pointer">
                      <v-chip color="#0D47A1" outlined>
                        <ValueTemplate style="text-transform: uppercase;" :title="' job type'" :value="row.role_name"></ValueTemplate>
                        
                      </v-chip>
                    </div>
                    <div v-else-if="th.field === 'during_probs_salary'" link v-on:click="routeToDetail(row)"
                      class="cursor-pointer">
                      <ValueTemplate :title="'no basic salary'" :value="formatMoney(row.during_probs_salary)">
                      </ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'after_probs_salary'" link v-on:click="routeToDetail(row)"
                      class="cursor-pointer">
                      <ValueTemplate :title="'no after probation period'" :value="formatMoney(row.after_probs_salary)">
                      </ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'country'" link v-on:click="routeToDetail(row)" class="cursor-pointer">
                      <ValueTemplate :title="'no after probation period'" :value="row.country"></ValueTemplate>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img width="30" :src="$assetURL('media/error/empty.png')" class="row-not-found-image mr-4" />
                      Uhh... There are no employee at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 9" :key="`_$skeleton_$_locader_${idr}`">
                <td v-for="idk in 9" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <template>
          <v-layout v-if="employeeList?.length" class="light-border-top mt-4">
            <v-flex md6>
              <label class="btx-label p-4">{{ showingString }}</label>
            </v-flex>

            <v-flex md6>
              <v-pagination color="blue darken-4" v-model="currentPage" :length="totalPages" :total-visible="7"
                v-on:input="getSegments()"></v-pagination>
            </v-flex>
          </v-layout>
        </template>
      </v-col>
    </v-row>
    <CreateSegment v-if="generateSegmentDialog" :dialog="generateSegmentDialog" @close="generateSegmentDialog = false"
      @success="getSegments()" />
    <Dialog :dialog.sync="deleteDialog" :dialogWidth="750">
      <template v-slot:title> Delete Segments</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              <span class="text-capitalize">Segments </span>
              <b class="red--text text--lighten-1">{{ deleteText }}</b> will be deleted forever and
              cannot be retrieved later.<br />Are you sure about deleting it?
            </p>
          </v-col>
          <v-col md="12" class="pb-0 my-auto">
            <em>Note: All transactions of this segment will also be deleted.</em>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn class="white--text" :loading="pageLoading" :disabled="pageLoading" depressed color="red lighten-1" tile
          v-on:click="deleteSegment()">
          Yes! Delete
        </v-btn>
        <v-btn depressed tile :disabled="pageLoading" v-on:click="deleteDialog = false">
          No, Close
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import CreateSegment from "@/view/module/marketing/CreateSegment";
import ValueTemplate from "@/view/components/ValueTemplate";
// import SelectInput from "@/view/components/SelectInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
// import Chip from "@/view/components/Chip";
import { QUERY, PATCH, POST } from "@/core/services/store/request.module";
import { GET_BROADCASTS } from "@/core/lib/marketing.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import { CHANGE_SEGMENTS_STATUS, DELETE_SEGMENTS } from "@/core/lib/marketing.lib";
import Dialog from "@/view/components/Dialog";
import JwtService from "@/core/services/jwt.service";
import { filter, map } from "lodash";
import EmployeeHeader from "@/view/components/EmployeeHeader.vue";
import { SearchEventBus } from "@/core/lib/search.lib";
import moment from "moment-timezone";
import { formatMoney } from "accounting-js";


export default {
  name: "employee-create",
  title: "Create employee",
  components: { ValueTemplate, CreateSegment, Dialog, EmployeeHeader, AutoCompleteInput },
  data() {
    return {
      pageLoading: false,
      saveAddress: null,
      dataLoading: false,
      selectstatus: null,
      employee: [],
      all: 0,
      customerId: null,

      search: {
        employees: null,
        month: null,
        department: null,
      },
      searchEnabled: false,
      searchParam: null,

      generateSegmentDialog: false,
      deleteDialog: false,
      deleteText: null,
      deleteId: null,
      showingString: null,
      currentPage: 1,
      defaultColDefs: [],
      customThead: [],
      showingFrom: 1,
      showingTo: 10,
      //status: true,
      loadPagination: false,
      totalrows: null,
      totalPages: null,
      status_list: null,
      deleteEndpoint: null,
      employee_list_details: [
        {
          action: { value: "", key: "ACTION" },
          status: { value: "false", key: "STATUS" },
          id: { value: "DKGH55645", key: "ID" },
          joining_date: { value: "22/08/2023", key: "JOINING DATE" },
          name: { value: "MARK SEN", key: "NAME" },
          phone_no: { value: "+64 83454545", key: "PHONE NO" },
          probation_period: { value: "3 MONTH", key: "PROBATION PERIOD" },
          job_role: { value: "FULL TIME", key: "JOB ROLE" },
          basic_salary_rm: { value: "39000.00", key: "BASIC SALARY" },
          after_prob_salary_rm: { value: "22000.00 - 29/07/2023", key: "AFTER PROB SALARY" },
          country: { value: "MALAYSIA", key: "COUNTRY" },
          role: { value: "MANAGER" },
          mykad: { value: "", key: "MY KAD" },
          passport: { value: "SDKHL346" },
          visa: { value: "4575FGHJ" },
          department: { value: "SOFTWARE" },
          email: { value: "marksend@gmail.com" },
          salar_during_probation_rm: { value: "34000.00 - 04/01/2024" },
          monthly_salary_rm: { value: "18/03/2023" },
          images: {
            img_link:
              "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          },
        },
        {
          action: { value: "", key: "ACTION" },
          status: { value: "true", key: "STATUS" },
          id: { value: "ABC4EG564", key: "ID" },
          joining_date: { value: "27/08/2023", key: "JOINING DATE" },
          name: { value: "THOMAS", key: "NAME" },
          phone_no: { value: "+64 834545655", key: "PHONE" },
          probation_period: { value: "4 MONTH", key: "PROBATION PERIOD" },
          job_role: { value: "PART TIME", key: "JOB ROLE" },
          basic_salary_rm: { value: "12000.00", key: "BASIC SALARY (RM)" },
          after_prob_salary_rm: { value: "35000.00 - 12/03/2023", key: "AFTER PROB SALARY (RM)" },
          country: { value: "SINGAPORE", key: "COUNTRY" },
          role: { value: "CTO" },
          mykad: { value: "45KHK565" },
          passport: { value: "SDKFDH34" },
          visa: { value: "DFJU0439" },
          department: { value: "CRM" },
          email: { value: "thomas@gmail.com" },
          salar_during_probation_rm: { value: "14000.00 - 20/04/2023" },
          monthly_salary_rm: { value: "10/07/2024" },
          images: {
            img_link:
              "https://images.unsplash.com/photo-1457449940276-e8deed18bfff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
          },
        },
      ],
      months: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "September",
          value: 9,
        },
        {
          name: "October",
          value: 10,
        },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],
      employeeList: [],
      dragStartIndex: null,
      dragOverIndex: null,
      employeeTableHeading: [
        { title: "ACTION", status: true },
        { title: "STATUS", status: true },
        { title: "ID", status: true },
        { title: "JOINING DATE", status: true },
        { title: "NAME", status: true },
        { title: "PHONE NO", status: true },
        { title: "PROBATION PERIOD", status: true },
        { title: "JOB ROLE", status: true },
        { title: "BASIC SALARY (RM)", status: true },
        { title: "AFTER PROB SALARY (RM)", status: true },
        { title: "COUNTRY", status: true },
      ],
    };
  },
  mounted() {
    this.getSegments();
    this.getEmployee();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Employee");

    SearchEventBus.$on("start-search", (argument) => {
      this.searchEnabled = false;
      this.searchParam = argument;
      if (this.searchParam) {
        this.searchEnabled = true;
      }
      this.getSegments(argument);
    });
    SearchEventBus.$emit("search-template", true);
  },
  computed: {
    ...mapGetters(["listingSearch"]),

  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
    SearchEventBus.$emit("search-template", false);
  },
  // watch: {

  //   $route() {
  //     this.getSegments();
  //   },
  // },

  methods: {
    updateAddressPerson(param) {
      this.selectstatus = param;
      this.getSegments();
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    statusSelect(data) {
      this.selectstatus = data;
      this.getSegments();
    },
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getSegments();
          });
      });
    },
    exportEmployee() {
      alert("hello");
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "customer/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    activeInactive(row) {
      const _this = this;
      if (row.activated == false) {
        row.activated = 0;
      } else {
        row.activated = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `employee/${row.activated}/${row.id}`,
          data: {
            id: row.id,
            status: row.activated,
          },
        })
        .then(() => {
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Status Updated Successfully." },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getSegments();
        });
    },
    getBgColor(value) {
      if (value && value == "all") {
        return "#90caf9";
      } else if (value && value == "active") {
        return "#6da16e";
      } else if (value && value == "inactive") {
        return "#c78a8a";
      }
    },
    getAvatarColor(value) {
      if (value && value == "all") {
        return "blue";
      } else if (value && value == "active") {
        return "green";
      } else if (value && value == "inactive") {
        return "red";
      }
    },
    resetEmployee() {
      this.search.employees = null;
      this.search.month = null;
      this.getSegments();
    },
    searchEmployee() {
      const _this = this;

      //  _this.employees= _this.search.employees
      //  _this.month= _this.search.month
      //  _this.department= _this.search.department
      this.$store
        .dispatch(QUERY, {
          url: "/list-employee",
          data: {
            employees: _this.search.employees,
            month: _this.search.month,
            department: _this.search.department,
          },
        })
        .then((data) => {
          _this.employeeList = data.rows;
          _this.totalrows = data.totalrows;
          _this.totalPages = data.totalPages;
          _this.currentPage = data.current_page;
          // console.log(_this.employeeList)
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    getEmployee() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: "/get-employee",
        })
        .then((data) => {
          _this.employee = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },

    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").replaceAll("(", "").replaceAll(")", "").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.employeeTableHeading[this.dragStartIndex];
      this.employeeTableHeading.splice(this.dragStartIndex, 1);
      this.employeeTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    goToSegmentDetail(id) {
      this.$router.push({
        name: "broadcast-detail",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    goBrodcastupdate(id) {
      this.$router.push({
        name: "update-employee",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    // id
    routeToDetail(row) {
      this.$router.push({
        name: "employee-detail",
        params: { id: row.id, row: row },
        query: { t: new Date().getTime() },
      });
    },
    async getSegments(search = null) {
      const _this = this;

      this.pageLoading = true;
      try {
        const { rows, showing_string, current_page, totalrows, totalPages, status_list, theads, showingFrom, showingTo, all } =
          await GET_BROADCASTS(
            this.currentPage,
            this.selectstatus,
            search
          );

        this.employeeList = rows;
        this.currentPage = current_page;

        //  console.log(current_page)
        this.showingString = showing_string;
        this.all = all;

        this.showingFrom = showingFrom;
        this.showingTo = showingTo;

        this.totalrows = totalrows;
        this.totalPages = totalPages;
        this.status_list = status_list;
        let thead = theads;

        this.defaultColDefs = thead;

        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
    async segmentsDeleteSuccess() {
      await this.getSegments();
      this.deleteDialog = false;
    },
    async deleteSegment() {
      const _this = this;
      const params = {
        selected: [_this.deleteId],
      };
      console.log(params);
    },

    // dragstart(index) {
    //   this.dragStartIndex = index;
    // },
    // dragover(index) {
    //   this.dragOverIndex = index;
    // },
    // dragEnd() {
    //   const dragStartElement = this.employeeTableHeading[this.dragStartIndex];
    //   this.employeeTableHeading.splice(this.dragStartIndex, 1);
    //   this.employeeTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    // },
    // drastart(index) {
    //   this.dragging_element_index = index;
    // },
    // goToSegmentDetail(id) {
    //   this.$router.push({
    //     name: "broadcast-detail",
    //     params: { id: id },
    //     query: { t: new Date().getTime() },
    //   });
    // },
    // goBrodcastupdate(id) {
    //   this.$router.push({
    //     name: "update-broadcast",
    //     params: { id: id },
    //     query: { t: new Date().getTime() },
    //   });
    // },
    // id
    // routeToDetail(row) {
    //   this.$router.push({
    //     name: "employee-detail",
    //     params: { id: row.id, row: row },
    //     query: { t: new Date().getTime() },
    //   });
    // },
    // async getSegments() {
    //   const _this = this;
    //   this.pageLoading = true;
    //   try {
    //     const { rows, showing_string, totalPages } = await GET_BROADCASTS(
    //       this.currentPage
    //       //this.per_page
    //     );
    //     this.employeeList = rows;
    //     this.showingString = showing_string;
    //     this.totalPages = totalPages;
    //   } catch (error) {
    //     _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //   } finally {
    //     this.pageLoading = false;
    //     this.loadPagination = true;
    //   }
    // },
    // async segmentsDeleteSuccess() {
    //   await this.getSegments();
    //   this.deleteDialog = false;
    // },
    // async deleteSegment() {
    //   const _this = this;
    //   const params = {
    //     selected: [_this.deleteId],
    //   };
    //   this.pageLoading = true;
    //   try {
    //     await DELETE_SEGMENTS(params);
    //     _this.$store.commit(SET_MESSAGE, [
    //       { model: true, message: "Segment delete successfully." },
    //     ]);
    //     await this.getSegments();
    //     this.deleteDialog = false;
    //   } catch (error) {
    //     _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //   } finally {
    //     this.pageLoading = false;
    //   }
    // },
    async deleteConfirm(name, id) {
      this.deleteText = name;
      this.deleteId = id;
      this.deleteDialog = false;
    },
  },
};
</script>
<style>
.table_height .v-data-table__wrapper {
  height: calc(100vh - 365px);
}

.employee-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.employee-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
